<template>
  <div id="common">
    <div class="page" size="A4" v-for="page in pages" :key="'page' + page">
      <v-row class="pt-2">
        <v-col cols="1">
          <v-avatar size="30">
            <img
              src="https://casasdastintas.com.br/_nuxt/icons/icon_512x512.9bcf77.png"
            />
          </v-avatar>
        </v-col>
        <v-col style="line-height: 10pt">
          <b style="font-size: 10px">Rede Casas das Tintas</b>
          <template v-if="hasAddress">
            <div>
              {{ sale.company.main_address.address }}
              {{ sale.company.main_address.number }},
              {{ sale.company.main_address.district }}

              {{ sale.company.main_address.city }} -
              {{ sale.company.main_address.state }},
              {{ $format.cep(sale.company.main_address.postal_code) }},
            </div>
          </template>
          <div v-if="hasPhones" class="d-flex flex-row">
            <span class="mr-2">Tel:</span>
            <div v-for="(phone, index) in phones" :key="index">
              {{ phone }}

              <span class="mr-1" v-if="phones.length > index + 1">,</span>
            </div>
          </div>
        </v-col>

        <v-col cols="3" style="font-size: 10px; line-height: 15pt">
          <div class="text-center">
            <span v-if="sale.operation_id == 1">
              {{ status[sale.status] }}
            </span>
            <span v-if="sale.operation_id == 2">
              {{ sale.operation.description }}
            </span>
          </div>
          <div class="text-center">N° {{ sale.number }}</div>
          <div class="text-right">
            {{ `${page}/${pages} ` }}
          </div>
        </v-col>
      </v-row>
      <div>
        <hr class="mt-2 mb-4" />
      </div>
      <v-row class="d-flex justify-space-between align-center mb-2">
        <v-col cols="7">
          <b>Dados Do Cliente</b>
          <div v-if="hasCustomer">
            <span>
              <b>Nome:</b> {{ sale.customer.code }} -{{ sale.customer.name }}
            </span>
          </div>
          <div v-else>
            <span> <b>Nome: </b> Consumidor Final </span>
          </div>
        </v-col>

        <v-col cols="4">
          <div>
            <b>Vendedor: </b> {{ sale.salesman.code }} -{{
              sale.salesman.short_name
            }}
            <br />
            <b>Data de emissão: </b>{{ $format.dateTimeBr(sale.created_at) }}
            <br />
          </div>
        </v-col>
      </v-row>

      <v-data-table
        :page="page"
        :headers="headers"
        :items="getItems(page)"
        :items-per-page="getItemsPerPage(page)"
        hide-default-footer
        disable-sort
        dense
      >
        <template v-slot:[`item.product`]="{ item }">
          <span v-if="item.product">
            {{ item.product_company.company.code }} -

            {{ item.product.code }} - {{ item.product.short_name }}
          </span>
        </template>

        <template v-slot:[`item.quantity`]="{ item }">
          <span v-if="item.product">
            {{ $format.decimal(item.quantity) }}
            {{ item.product_variant.unity.symbol }}</span
          >
        </template>

        <template v-slot:[`item.price`]="{ item }">
          <span v-if="item.product"> {{ $format.decimal(item.price) }}</span>
        </template>
        <template v-slot:[`item.net_price`]="{ item }">
          <span v-if="item.product"> {{ getNetPrice(item) }}</span>
        </template>

        <template v-slot:[`item.net_total`]="{ item }">
          <span v-if="item.product">
            {{ $format.decimal(item.net_total) }}</span
          >
        </template>
      </v-data-table>

      <v-row style="word-break: break-word" v-if="page == 1">
        <v-col>
          <span v-if="sale.notes">Observação: {{ sale.notes }}</span>
        </v-col>
      </v-row>

      <template v-if="page == pages">
        <v-row class="mt-2">
          <v-col class="d-flex flex-column text-right">
            <span>
              <b class="mr-1">Sub-Total: </b>
              {{ $format.moneyBr(sale.product_total) }}
            </span>

            <span>
              <b class="mr-1">Desconto:</b>
              {{ $format.moneyBr(sale.discount) }}
            </span>

            <span>
              <b class="mr-1"> Total:</b>
              {{ $format.moneyBr(sale.net_total) }}
            </span>
          </v-col>
        </v-row>
        <template v-if="sale.status == 'proposal'">
          *** Orçamento de venda (válido por 7 dias) ***
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itensPerPage: 12,

      headers: [
        { text: "Produto", value: "product" },
        {
          text: "Quantidade",
          value: "quantity",
          align: "center",
          width: "5%",
        },
        {
          text: "P. Bruto",
          value: "price",
          align: "center",
          width: "10%",
        },
        {
          text: "P. Liquido",
          value: "net_price",
          align: "center",
          width: "10%",
        },
        { text: "Total", value: "net_total", align: "center", width: "10%" },
      ],

      status: {
        sale: "Venda",
        proposal: "Orçamento",
        draft: "Rascunho",
      },

      sale: {
        company: {
          main_address: {},
          phones: [],
        },
        customer: {
          main_address: {},
        },
        operation: {},
        salesman: {},
        sale_products: [{}],
      },
    };
  },

  async created() {
    this.$loading.start();

    await this.$http
      .show("sale/sale", this.$route.params.id)
      .then((response) => {
        this.sale = response.sale;
        this.$loading.finish();
      })
      .catch((error) => {
        this.$loading.finish();
      });
  },

  computed: {
    print_type() {
      return this.$store.getters["app/getTypePrint"];
    },
    hasAddress() {
      return this.sale.company.main_address != null;
    },
    hasPhones() {
      return this.sale.company.phones.length > 0;
    },
    phones() {
      return this.sale.company.phones.map((phone) => phone.contact);
    },
    hasCustomer() {
      return this.sale.customer_id;
    },
    personType() {
      return this.sale.customer.person_type;
    },
    docLabel() {
      return this.personType == "juridica" ? "CNPJ:" : "CPF:";
    },
    docNumber() {
      return this.personType == "juridica"
        ? this.$format.cnpj(this.sale.customer.cnpj)
        : this.$format.cpf(this.sale.customer.cpf);
    },

    pages() {
      return Math.ceil(this.itensCount / this.itensPerPage);
    },

    sale_products() {
      return this.sale.sale_products;
    },
    itensCount() {
      if (this.sale_products) {
        return this.sale_products.length;
      }
      return 1;
    },
    hasPaymentTerms() {
      return this.sale.sale_payment_terms.length > 0;
    },
  },
  methods: {
    hasItemOnIndex(page, item) {
      return this.getIndex(page, item) < this.itensCount;
    },
    getNetPrice(product) {
      let netPrice =
        product.price - (product.price * product.discount_percent) / 100;

      return this.$format.decimal(netPrice);
    },

    getItems(page) {
      let items = this.sale_products.slice(
        (page - 1) * this.getItemsPerPage(page),
        page * this.getItemsPerPage(page)
      );
      if (items.length < this.getItemsPerPage(page)) {
        for (let i = items.length; i < this.getItemsPerPage(page); i++) {
          items.push({});
        }
      }
      return items;
    },

    getItemsPerPage(page) {
      if (page == 1) {
        return 20;
      }
      if (page == this.pages) {
        return 12;
      } else {
        return 20;
      }
    },
  },
};
</script>

<style  lang="scss">
@import "@/assets/style/prints/CommonPrinting.scss";
</style>

